import axios from 'axios';
import jwt_decode from 'jwt-decode'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL


export const login = async (body) => {
  const url = `${REACT_APP_API_URL}/login`
  try {
    const response = await axios.post(url,{...body})
    const {profile, user_id, name} = jwt_decode(response.data.access_token)
    response.data.profile = profile
    response.data.user_id = user_id
    response.data.name = name
    return response
  } catch (error) {
    const {response} = error
    return response
  }
}

