import React from 'react'
import { IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import complexityDictionary from '../../dictionaries/complexity.json'
import { HelpOutline } from '@material-ui/icons';

const useStyles = makeStyles({
  high: {
    background: '#EA565733',
    borderRadius: 3,
    border: 0,
    color: 'black',
  },
  medium: {
    background: '#FFE15633',
    borderRadius: 3,
    border: 0,
    color: 'black',
  },
  low: {
    background: '#2FBF7133',
    borderRadius: 3,
    border: 0,
    color: 'black',
  },
  minimum: {
    background: '#2FA5BF33',
    borderRadius: 3,
    border: 0,
    color: 'black',
  }
});

const Complexity = ({complexity}) => {
  const classes = useStyles(complexity);
  const complexityAux = complexityDictionary.find(e => e.complexity === complexity.title)
  return (
    <>
    
        <TextField 
          classes={{
            root: complexity.title === 'Mínima' ? classes.minimum : complexity.title === 'Baja' ? classes.low : complexity.title === 'Moderada' ? classes.medium : classes.high,
            label: classes.label,
          }}
          size='small'
          inputProps={{ style: {textAlign: 'center'} }}  
          fullWidth
          label="Complejidad"
          variant="outlined" 
          disabled 
          margin={'normal'} 
          value={complexity.title}
          InputProps={{
            endAdornment: 
            <InputAdornment position="end">
              <Tooltip 
                placement="right"
                arrow
                title={
                  complexity.text &&
                  <div>
                    {complexityAux && <div style={{ fontSize: "small", margin: "6px 0" }}>&bull; {complexityAux?.description}</div>}
                    {complexityAux && <div style={{ fontSize: "small", margin: "6px 0" }}>&bull; {complexityAux?.approach}</div>}
                  </div>
                }
              >
                <HelpOutline style={{color: '#F59D00'}} />
              </Tooltip>
            </InputAdornment>,
          }}
        />
    </>
  )
}

export default Complexity
