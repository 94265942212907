import React, { useState, useEffect } from 'react'
import { Grid, TextField, Dialog, DialogTitle, Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PermissionsList from './PermissionsList'
import { getPermissions } from '../../services/permissions/getPermissions'
import { createRole } from '../../services/roles/createRole'

const useStyles = makeStyles(() => ({
  dialog: {
    height: 'auto',
    width: '30%',
    padding: '2%',
    margin: 'auto'
  },
}));


const AddRolesDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const [newRole, setNewRole] = useState('')
  const [permissionsListData, setPermissionsListData] = useState([]);
  const [permissionsData, setPermissionsData] = useState([])

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const permissions = await getPermissions()
        setPermissionsData([...permissions])
      } catch (error) {
        alert('Error al traer los permisos')
      }
    }
    fetchPermissions()
    return () => {
      
    }
  }, [])
  

  const handleClick = async () => {
    try {
      const permissions = permissionsListData.map(permission => permission.name)
      const data = await createRole({name: newRole, permissions: permissions})
      alert(`Rol "${data.name}" creado correctamente`)
      window.location.reload()
    } catch (error) {
      alert('Hubo un error al crear el rol')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      maxWidth="lg"
      classes={{
        paperWidthLg: classes.dialog, // class name, e.g. `classes-nesting-root-x`
      }}
    >
      <DialogTitle style={{padding: 0, marginBottom: '3%'}}>
        Crear Rol
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{left: '70%'}}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Grid container style={{gap: 15}}>
        <Grid item xs={12}>
          <TextField
            label="Nuevo rol"
            value={newRole}
            onChange={e => setNewRole(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <PermissionsList permissionsData={permissionsData} permissionsListData={permissionsListData} setPermissionsListData={setPermissionsListData} />
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{float: 'right', backgroundColor: '#F59D00'}}
            onClick={handleClick}
            disabled={newRole === '' || permissionsListData.length === 0}
          >
            CREAR ROL
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default AddRolesDialog