const jwt = require("jsonwebtoken")

const METABASE_SITE_URL = process.env.REACT_APP_METABASE_SITE_URL
const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY

export const getIframeUrl = () => {

  const payload = {
    resource: { dashboard: 1 },
    params: {},
    exp: Math.round(Date.now() / 1000) + (30 * 60) // 30 minute expiration
  };
  const token = jwt.sign(payload, METABASE_SECRET_KEY)
  
  return `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=true`
}


