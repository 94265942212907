import React from 'react'
import { Redirect } from "react-router-dom"
import NavBar from './NavBar/NavBar'
import { validateSession } from '../utils'

export const ProtectedRoute = ({ children }) => {
  return validateSession() ? (
    <div>
      <NavBar />
      {children}
    </div>
  ) : (
    <Redirect
      to={{
        pathname: "/login"
      }}
    />
  )
};