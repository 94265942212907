import axios from 'axios'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const processAppointment = async (appointment) => {

  try {
    const url = `${REACT_APP_API_URL}/appointments/${appointment.id}/process`
    const headers = {
      'Authorization': `Bearer ${await localStorage.getItem('dashboard_access_token')}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    console.log(appointment)
    debugger
    const {data} = await axios.get(url, {headers: headers});
    return data;
  } catch (error) {
    
    return error;
  }
}


