import React, { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, ListItemText, Select } from '@material-ui/core';
import { getAllRoles } from '../../services/roles/getAllRoles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function SelectRoles({onChange, roles}) {

  const [rolesToShow, setRolesToShow] = useState([])

  useEffect(() => {
    console.log('roles', roles)
    const fetchRoles = async () => {
      try {
        const data = await getAllRoles()
        const parsedRoles = data.map(role => role.name)
        setRolesToShow(parsedRoles)
      } catch (error) {
        alert('Error al traer los roles')
      }
    }
    fetchRoles()
    return () => {
      
    }
  }, [])

  return (
    <>
      <FormControl variant='outlined' fullWidth>
      <InputLabel size='small' style={{marginTop: '3%'}} id="role">Seleccionar los roles </InputLabel>
      <Select
        multiple
        value={roles}
        
        onChange={onChange}
        inputProps={{ shrink: true }}
        variant={'outlined'}
        style={{marginTop: '12px'}}
        fullWidth
        renderValue={(selected) => selected.join(', ')}
      >
        {rolesToShow.map((name) => (
          <MenuItem key={name} value={name}>
            {roles.includes(name) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
      </FormControl>
    </>
  );
}
