import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import { getUsers } from '../../services/users/getUsers'
import SelectProfile from './SelectProfile'
import { ToggleOff, ToggleOn, DeleteForever } from '@material-ui/icons'
import NewUserDialog from './NewUserDialog'
import { updateUser } from '../../services/users/updateUser'
import { switchActivateUser } from '../../services/users/switchActivateUser'
import { deleteUser } from '../../services/users/deleteUser'
import { Button, Paper } from '@material-ui/core'
import AddUserIcon from '../Icons/AddUserIcon'
import SelectRoles from './SelectRoles'

export default function UsersManagementTable() {
  const [users, setUsers] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const handleClickOpen = () => {
    setOpenDialog(true)
  };

  const handleClose = () => {
    setOpenDialog(false)
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getUsers()
        setUsers(data.filter(element => !element.username.toLowerCase().includes(localStorage.getItem('dashboard_logged_user').toLowerCase())))
      } catch (error) {
        alert('Error al traer los usuarios')
      }
    }
    fetchUsers()
    return () => {
      
    }
  }, [])

  return (
    <div style={{padding: '20px'}}>
      <NewUserDialog open={openDialog} handleClose={handleClose} />
      <MaterialTable
        columns={[
          { 
            title: "id", 
            field: "id", 
            type: "string",
            hidden: true,
            editable: false,
            searchable: false,
          },
          { 
            title: "Nombre de Usuario", 
            field: "username", 
            type: "string",
            width: "10%",
            editable: false,
            searchable: true,
          },
          { 
            title: "Nombre", 
            field: "display_name", 
            type: "string",
            width: "10%",
            editable: false,
            searchable: true,
          },
          { 
            title: "Email", 
            field: "email", 
            type: "string",
            width: "10%",
            editable: false,
            searchable: true,
          },
          { 
            title: "Roles", 
            field: "roles", 
            type: "string",
            width: "55%",
            render: (rowData) => rowData.roles.join(' | '),
            searchable: false,
            editComponent: (props) => (
              <SelectRoles 
                roles={props.value}
                onChange={(event) =>
                  props.onChange(event.target.value)
              }
              />),
          },
          { 
            title: "Perfil", 
            field: "profile",
            width: "10%",
            searchable: true,
            editComponent: (props) => (
              <SelectProfile 
                profile={props.value}
                onChange={(event) =>
                  props.onChange(event.target.value)
              }
              />),
          },
          { 
            title: "Usuario Activo", 
            field: "is_active",
            render: (rowData) => rowData.is_active ? <span style={{color: '#2FBF71'}}>Activo</span> : <span style={{color: '#EA5657'}}>Desactivado</span>,
            width: "5%",
            editable: false,
          },
        ]}
        title={'Gestión de usuarios'}
        options={{
          headerStyle: {
            position: 'sticky',
            top: 0,
            zIndex: 1
          },
          minbodyHeight: "85vh",
          maxBodyHeight: '85vh',
          actionsColumnIndex: -1,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          search: true,
          searchFieldVariant: 'outlined',
          searchFieldStyle: {height: '40px'},
          paging: true,
          sorting: false,
          rowStyle: (x, index) => {
            if (index % 2) {
              return { fontFamily: "roboto", fontSize: "small" }
            }
            return { fontFamily: "roboto", fontSize: "small", backgroundColor: '#EEEEEE', borderTopLeftRadius: '8px' }
          },
        }}
        data={users}
        editable={{
          onRowUpdate: (newData, oldData) =>
            updateUser({id: newData.id, profile: newData.profile, roles: newData.roles}).then((result) => {
              setUsers([...users.map(element => element.id === result.id ? result : element)])
            }).catch((err) => {
          }),
        }}
        components={{
          Container: props => <Paper {...props} elevation={0}/>,
          Toolbar: (props) => (
            <div
              style={{
                backgroundColor: '#EEEEEE',
                paddingBottom: '15px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{width: '85%', display: 'inline-block'}}>
                <MTableToolbar {...props} />
              </div>
              <div style={{width: '20%', display: 'inline-block'}}>
                <Button
                  variant="contained"
                  size='small'
                  disableElevation
                  endIcon={<AddUserIcon />}
                  style={{backgroundColor: '#F59D00', height: '40px', float: 'right'}}
                  onClick={() => handleClickOpen()}

                >
                  AGREGAR USUARIO
                </Button>
              </div>
            </div>
          )
        }}
        actions={[
          rowData => ({
            name: "disabledUser",
            tooltip: 'Activar/Desactivar usuario',
            icon: () => rowData.is_active ? <ToggleOn style={{color: 'green'}} /> : <ToggleOff style={{color: 'red'}} />,
            onClick: (evt, data) => {
              (async function() {
                const user = await switchActivateUser({id: data.id})
                setUsers([...users.map(element => element.id === user.id ? user : element)])
              })();
            }
          }),
          rowData => ({
            name: "deleteUser",
            tooltip: 'Eliminar usuario',
            icon: () =>  <DeleteForever style={{color: 'red'}} />,
            onClick: (evt, data) => {
              (async function() {
                if (window.confirm(`Esta por eliminar el usuario: ${data.username} (${data.email}) \n\n ¿Desea continuar?`)){
                  const isDeleted = await deleteUser({id: data.id})
                  if(isDeleted === true){
                    alert('Usuario eliminado correctamente')
                  }
                  setUsers([...users.filter(element => element.id !== data.id)])
                }
              })();
            }
          }),
        ]}
        localization={{
          toolbar: {
              nRowsSelected: '{0} registro(s) seleccionado(s)',
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar'
          },
          header: {
              actions: 'Acciones'
          },
          body: {
              emptyDataSourceMessage: 'No hay usuarios',
              filterRow: {
                  filterTooltip: 'Filtro'
              },
              editRow: {
                deleteText: '¿Seguro que desea desactivar el usuario?'
            }
          },
          pagination: {
            labelRowsSelect: 'usuarios',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera página',
            previousTooltip: 'Anterior',
            nextTooltip: 'Siguiente',
            lastTooltip: 'Última página'
          }
        }}
      />
    </div>
  );
}
