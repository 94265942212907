import React, { useState } from 'react'
import exportFromJSON from 'export-from-json'
import { Grid, TextField, Button } from '@material-ui/core'
import { filterPrescriptions } from '../../services/prescriptions/filterPrescriptions'

const ExportProtocols = () => {
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')

  const handleClick = async () => {
    try {
      const query = `created__gt=${new Date(`${dateFrom}T00:00:00`).getTime() / 1000}&created__lt=${new Date(`${dateTo}T00:00:00`).getTime() / 1000 + 86400}`
      const data = await filterPrescriptions(query)
      data.forEach(e => {
        e['user.smg'] = `smg_${e['user.smg_str']}`
        delete e['user.smg_str']
      })
      if (data.length > 0) {
        exportFromJSON({ data: data, fileName: 'export', exportType: 'xls', withBOM: true })
      } else {
        alert('No se encontraron recetas entre el intervalo de fechas deseado')
      }
    } catch (error) {
      console.log(error)
      alert('Hubo un error al intentar buscar las recetas')
    }
  }

  return (
    <Grid container style={{marginTop: '10%'}}>
      <Grid item xs={12}>
        <div style={{textAlign: 'center'}}>
          <div style={{width: '45%', display: 'inline-block'}}>
            <div style={{float: 'left', marginBottom: '2%'}}>Desde: </div>
            <TextField fullWidth type='date' variant='outlined' value={dateFrom} onChange={({target}) => setDateFrom(target.value)}/>
          </div>
          <div style={{width: '45%', display: 'inline-block', marginLeft: '5%'}}>
            <div style={{float: 'left', marginBottom: '2%'}}>Hasta: </div>
            <TextField fullWidth type='date' variant='outlined' value={dateTo} onChange={({target}) => setDateTo(target.value)}/>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth disabled={dateFrom === '' || dateTo === '' || dateFrom > dateTo} style={{float: 'right', marginTop: '10%', backgroundColor: '#F59D00'}} onClick={async () => await handleClick()}>
          DESCARGAR REPORTE
        </Button>
      </Grid>
    </Grid>
  )
}

export default ExportProtocols