import React, { useState } from 'react'
import { Grid, TextField, Dialog, DialogTitle, Button, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectProfile from './SelectProfile';
import { Close } from '@material-ui/icons';
import { createUser } from '../../services/users/createUser';
import SelectRoles from './SelectRoles';

const useStyles = makeStyles(() => ({
  dialog: {
    height: 'auto',
    width: '30%',
    padding: '2%',
    margin: 'auto'
  },
}));

const NewUserDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [mail, setMail] = useState('')
  const [profile, setProfile] = useState('')
  const [roles, setRoles] = useState([])
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    if (username && name && lastname && mail && profile && password && roles.length > 0) {
      if (mail.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
        const newUser = {
          name: `${name}`,
          lastname: `${lastname}`,
          email: `${mail.toLowerCase()}`,
          username: `${mail.toLowerCase()}`,
          password: password,
          profile: profile,
          roles: roles
        }
        try {
          const response = await createUser(newUser)
          alert(`El usuario ${response.username} ha sido creado con éxito.`)
          setLoading(false)
          window.location.reload()
        } catch (error) {
          alert('Hubo un problema al crear el usuario.')
          setLoading(false)
        }
      } else {
        alert('No estan permitidos los caracteres especiales.')
        setLoading(false)
      }
    } else {
      alert('Todos los datos son obligatorios')
      setLoading(false)
    }
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth="lg"
        classes={{
          paperWidthLg: classes.dialog, // class name, e.g. `classes-nesting-root-x`
        }}
      >
        <DialogTitle style={{padding: 0, marginBottom: '3%'}}>
          Crear Usuario
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            style={{left: '62%'}}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Grid container>
        <Grid item xs={12}>
            <TextField name="username" label="Username" variant="outlined" size={'small'} fullWidth autoComplete='off' value={username} onChange={({target}) => setUsername(target.value)}/>
          </Grid>
          <Grid item xs={12}>
            <TextField name="name" label="Nombre" variant="outlined" size={'small'} fullWidth style={{marginTop: '12px'}} autoComplete='off' value={name} onChange={({target}) => setName(target.value)}/>
          </Grid>
          <Grid item xs={12}>
            <TextField name="lastname" label="Apellido" variant="outlined" size={'small'} fullWidth style={{marginTop: '12px'}} autoComplete='off' value={lastname} onChange={({target}) => setLastname(target.value)}/>
          </Grid>
          <Grid item xs={12}>
            <TextField name="mail" label="Mail" variant="outlined" size={'small'} fullWidth style={{marginTop: '12px'}} autoComplete='off' value={mail} onChange={({target}) => setMail(target.value)}/>
          </Grid>
          <Grid item xs={12}>
            <SelectProfile onChange={({target}) => setProfile(target.value)} profile={profile} />
          </Grid>
          <Grid item xs={12}>
            <SelectRoles onChange={({target}) => setRoles(target.value)} roles={roles} />
          </Grid>
          <Grid item xs={12}>
            <TextField autoComplete='off' type="password" name="password" label="Contraseña" variant="outlined" size={'small'} fullWidth style={{marginTop: '12px'}} autoComplete='off' value={password} onChange={({target}) => setPassword(target.value)}/>
          </Grid>
          <Grid item xs={12}>
            {loading && <CircularProgress style={{marginTop: '24px', marginRight: '5%', float: 'right'}}/>}
            {!loading && <Button variant="contained" color="black" style={{marginTop: '24px', float: 'right', backgroundColor: '#F59D00'}} onClick={() => handleClick()}>
              CREAR USUARIO
            </Button>}
          </Grid>
        </Grid>
      </Dialog>
  )
}

export default NewUserDialog
