import React from 'react'
import { Redirect } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core'
import ExportProtocols from '../components/Export/ExportProtocols'
import { validateSession } from '../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
  },
  paper: {
    width: '30%',
    margin: '5% auto',
    padding: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
}))

export default function Export() {

  const classes = useStyles()

  if (!validateSession()) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container >
          <Paper className={classes.paper}>
            <Typography variant="h5" component="h2" align='center'>
              Reportes
            </Typography>
            <ExportProtocols />
          </Paper>
        </Grid>
      </div>
    </>
  );
}
