import React, { useState, useEffect } from 'react'
import  { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, CircularProgress } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { loginRequest } from "../Config"
import { callMsGraph } from "../graph"
import { login } from '../services/login/login'
import logo from "../img/logo.png"
import { validateSession } from '../utils'

function signInClickHandler(instance) {
  instance.loginRedirect();
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginTop: '10%',
  },
  paper: {
    width: "20%",
    borderRadius: "3%",
    boxShadow: "0 2px 5px",
    margin: "0 auto",
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function ADLogin({redirectUrl}) {
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  function handleLoginMS(body) {
    setLoading(true)
    login({...body, mail: body.mail.toLowerCase(), userPrincipalName: body.userPrincipalName.toLowerCase()}).then(({data, status}) => {
      if (status === 401) {
        alert('No posee autorización para esta aplicación. Por favor contacte con el administrador si cree que esto es un error.')
        setLoading(false)
      } else {
        let dt = new Date()
        dt.setMinutes(dt.getMinutes() + 30)
        let expire = new Date(dt).getTime()
        localStorage.setItem('dashboard_access_token', data.access_token)
        localStorage.setItem('dashboard_refresh_token', data.refresh_token)
        localStorage.setItem('dashboard_expire', expire)
        localStorage.setItem('dashboard_profile', data.profile)
        localStorage.setItem('dashboard_user_id', data.user_id)
        localStorage.setItem('dashboard_logged_user', data.name)
        setLoading(false)
        window.location.replace('/appointments')
      };
    }).catch(
      (e) => {
        alert('Hubo un problema al intentar iniciar sesión.')
        setLoading(false)
      }
    );
  }

  useEffect(() => {
    if (isAuthenticated){
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        callMsGraph(response.accessToken).then((response) => {
          handleLoginMS(response)
        });
      });
    } 
    return () => {
    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (validateSession()) {
    if (!redirectUrl.includes('prescription')) return <Redirect to='/prescriptions' />
    return <Redirect to={redirectUrl} />
  }
  
  return (
    <div className={classes.root}>
      <Grid container >
        <Paper className={classes.paper}>
          <Grid item xs={12}>
            <img src={logo} alt='Fleni' width="100%" />
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center', fontFamily: 'Roboto', color: 'black'}}>
            <h2>Dashboard Digital de Recetas</h2>
          </Grid>
          <Grid item xs={12} style={{position: 'relative'}}>
            {loading && <CircularProgress style={{marginTop: '12px', marginLeft: '44%'}}/>}
            {!loading && <Button variant="outlined" color="primary" style={{marginTop: '12px', width: '100%', backgroundColor: '#F59D00'}} onClick={() => signInClickHandler(instance)}>
              INICIAR SESIÓN&nbsp;<ExitToAppIcon />
            </Button>}
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}