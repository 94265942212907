import jwt_decode from 'jwt-decode'

const DASHBOARD_PROFILE = localStorage.getItem('dashboard_profile')
const DASHBOARD_EXPIRE = localStorage.getItem('dashboard_expire')
const DASHBOARD_ACCESSTOKEN = localStorage.getItem('dashboard_access_token')

export const validateUser = () => {
  if (DASHBOARD_PROFILE.includes('admin')){
    return true
  } 
  return false
}

export const getUserProfile = () => {
  const {profile} = jwt_decode(DASHBOARD_ACCESSTOKEN)
  return profile
}

export const validateSession = () => {
  if (!DASHBOARD_EXPIRE || DASHBOARD_EXPIRE < Date.now() || !DASHBOARD_ACCESSTOKEN){
    return false
  } 
  return true
}