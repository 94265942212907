import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';

export default function SelectProfile({onChange, profile}) {

  return (
    <>
      <TextField
        select
        fullWidth
        label="Seleccionar perfil"
        id="profile"
        name='profile'
        value={profile || ''}
        onChange={onChange}
        variant={'outlined'}
        style={{marginTop: '12px'}}
        size={'small'}
      >
        <MenuItem value={'auxiliar'}>Auxiliar</MenuItem>
        <MenuItem value={'doctor'}>Doctor</MenuItem>
        <MenuItem value={'admin'}>Administrador</MenuItem>
        <MenuItem value={'admin_doctor'}>Administrador & Doctor</MenuItem>
      </TextField>
    </>
  );
}
