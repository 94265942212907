require('dotenv').config()
const CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID
const BASE_URL = process.env.REACT_APP_AZURE_BASE_URL
const TENANT_ID = process.env.REACT_APP_AZURE_TENANT_ID

export const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        authority: `https://login.microsoftonline.com/${TENANT_ID}`,
        redirectUri: `${BASE_URL}/redirect`,
        postLogoutRedirectUri: `${BASE_URL}/login`
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};