import React, { useEffect, useState } from 'react'
import { Grid, TextField, Button, Fab, Paper, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import DialogHelper from '../Prescription/DialogHelper'
import { getAppointment } from '../../services/appointments/getAppointment';

const initalState = {
  "created": '',
  "user": {
      "user_id": "",
      "profile_id": "",
      "name": "",
      "lastname": null,
      "gender": "",
      "age": 40,
      "dni": null,
      "document": "_",
      "smg": null,
      "smg_str": null,
      "phone_number": null,
      "email": "",
      "pregnancy": false,
      "lactancy": false,
      "aco": "",
      "aco_number": "0"
  },
  "modified": 0,
  "order_number": 0,
  "processed": false,
  "subject": "",
  "title": "",
  "summary": "",
  "id": "0",
  "category": ""
}

const AppointmentContent = ({match, handleCloseDialog}) => {

  const [appointment, setAppointment] = useState(initalState)
  const [isLoading, setIsLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)


  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const {data, status} = await getAppointment(match.params.id)
        if (status === 200){
          setAppointment(data)
        } else {
          alert('Error al traer el pedido')
          window.location.replace("/appointments")
        }
      } catch (error) {
        alert('Error al traer el pedido')
        window.location.replace("/appointments")
      }
    }
    fetchAppointments()
    return () => {
      
    }
  }, [match.params.id])

  return (
    <div>
      { appointment.order_number &&
      <>
        <DialogHelper open={openDialog} handleClose={handleClose} />
        <Grid container style={{margin: '0 5%', maxWidth: '90%'}}>
          <Paper style={{width: '100%', padding: '3%', marginBottom: '2%', borderRadius: '8px'}}>
            {handleCloseDialog &&
              <IconButton style={{left: '95%', padding: 0}} onClick={handleCloseDialog}>
                <CloseIcon />
              </IconButton>
            }
            <Grid container spacing={4}>
              <Grid item xs={2}>
                <TextField size='small' inputProps={{ style: {textAlign: 'center'} }} fullWidth label="Orden" variant="outlined" margin={'normal'} disabled value={appointment.order_number}/>
              </Grid>
              <Grid item xs={3}>
                <TextField size='small' inputProps={{ style: {textAlign: 'center'} }} fullWidth label="Fecha" variant="outlined" disabled margin={'normal'} value={appointment.created ? new Date(appointment.created * 1000).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit' }) : ''}/>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{width: '100%', padding: '3%', marginBottom: '2%', borderRadius: '8px'}}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField size='small' inputProps={{ style: {textAlign: 'center'} }}  fullWidth label="Nombre completo" variant="outlined" disabled margin={'normal'} value={appointment ? `${appointment.user.name}` : ''}/>
              </Grid>
              <Grid item xs={2}>
                <TextField size='small' inputProps={{ style: {textAlign: 'center'} }}  fullWidth label="DNI" variant="outlined" disabled margin={'normal'} value={appointment.user.document.split('_')[1]}/>
              </Grid>
              <Grid item xs={1}>
                <TextField size='small' inputProps={{ style: {textAlign: 'center'} }}  fullWidth label="Edad" variant="outlined" disabled margin={'normal'} value={appointment.user.age}/>
              </Grid>
              <Grid item xs={2}>
                <TextField size='small' inputProps={{ style: {textAlign: 'center'} }}  fullWidth label="Género" variant="outlined" disabled margin={'normal'} value={appointment ? appointment.user.gender === 'm' ? 'Masculino' : 'Femenino' : ''}/>
              </Grid>
            </Grid>  
          </Paper>
          <Paper style={{width: '100%', padding: '3%', marginBottom: '2%', borderRadius: '8px'}}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {appointment.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography >
                  {appointment.subject}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography >
                  Categoría: {appointment.category}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{ __html: appointment.summary }} />
              </Grid>
            </Grid>  
          </Paper>
        </Grid>
      </>
      }
    </div>
  )
}

export default AppointmentContent
