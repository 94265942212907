import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { Grid, IconButton, MenuItem, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function PermissionsList({permissionsData, permissionsListData, setPermissionsListData}) {
  const classes = useStyles();
  const [permissionSelected, setPermissionSelected] = useState('');

  const handleDelete = (permissionToDelete) => () => {
    setPermissionsListData(permissionsListData.filter(e => e.id !== permissionToDelete.id));
  };

  const handleAdd = () => {
    if (permissionsListData.includes(permissionSelected)){
      alert('El permiso ya fue añadido')
    } else {
      setPermissionsListData([...permissionsListData, permissionSelected])
    }
  }

  return (
    
      <Grid container style={{gap: 15}}>
        <Grid item xs={12}>
          <Select
            style={{width: '87%'}}
            fullWidth
            displayEmpty
            variant='outlined'
            value={permissionSelected}
            onChange={(event) =>
              setPermissionSelected(event.target.value)
            }
          >
            <MenuItem value={''}>Seleccionar permiso</MenuItem>
            {permissionsData.map(e => <MenuItem key={e.id} value={e}>{e.name}</MenuItem>)}
          </Select>
          <IconButton  onClick={handleAdd}>
            <AddIcon />
          </IconButton>
        </Grid>
        {permissionsListData.length !== 0 && <Grid item xs={12} style={{gap: 15}}>
          <Paper component="ul" className={classes.root}>
            {permissionsListData.map((data, index) => {
            return (
                <li key={data.id}>
                  <Chip
                    label={data.name}
                    onDelete={handleDelete(data)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Paper>
        </Grid>}
      </Grid>
    
  );
}