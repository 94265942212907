/* eslint-disable no-extend-native */
import React from 'react';
import MaterialTable from '@material-table/core'
import { Tooltip, Grid } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import complexityDictionary from '../../dictionaries/complexity.json'
import { getPrescriptionsPaging } from '../../services/prescriptions/getPrescriptionsPaging'

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

const LastPrescriptionsTable = ({params}) => {
  const columns = (() => {
    let aux = []
    aux.push({ 
      field: "created", 
      title: 'Fecha', 
      width: "13%", 
      editable: false,
      search: true,
      sorting: true,
      defaultSort: 'desc',
      headerStyle: {textAlign: 'center', fontWeight: 'bold'},
      render: rowData => {
        var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit' };
        return new Date(rowData.created * 1000).toLocaleDateString('es-ES', options)
      }
    })
    aux.push({
      title: "Complejidad",
      field: "complexity.title",
      width: "5%",
      search: false,
      editable: false,
      sorting: false,
      headerStyle: {textAlign: 'center', fontWeight: 'bold'},
      render: rowData => {
        const complexityAux = complexityDictionary.find(e => e.complexity === rowData.complexity.title)
        return (
          <Tooltip 
            placement="top"
            arrow
            title={
              complexityAux &&
              <div>
                {complexityAux && <div style={{ fontSize: "medium", margin: "6px 0" }}>&bull; {complexityAux?.description}</div>}
                {complexityAux && <div style={{ fontSize: "medium", margin: "6px 0" }}>&bull; {complexityAux?.approach}</div>}
              </div>
            }
          >
            <div>{rowData.complexity.title?.capitalize()}</div>
          </Tooltip>
          )
      }
    })
    aux.push({
      title: "Lista de medicaciones",
      width: "50%",
      field: "drugs",
      editable: false,
      sorting: false,
      search: false,
      headerStyle: {textAlign: 'center', fontWeight: 'bold'},
      render: rowData => rowData.drugs.map((drug, index, arr) => {
        return (
            <Grid container direction="row" alignItems="center" key={index}>
              <Grid item xs={1} style={{ marginTop: '2%' }}>
                {(drug.allergy !== null && drug.allergy !== 'No.') && 
                <Tooltip arrow placement="top"  style={{ fontSize: "medium" }} title={drug.allergy}>
                  <WarningIcon />
                </Tooltip>}
              </Grid>
              <Grid item xs={11}>
                <div style={{ paddingLeft: '5%' }}>
                  <div>
                    <span>{`${drug.commercial_name?.capitalize()} (${drug.name?.capitalize()}, ${drug.presentation?.capitalize()}) - ${drug.classification ? drug.classification : ''}`}</span>
                  </div>
                  <div>
                    <span><b>Envases:</b> &nbsp;</span>
                    <span>{drug.quantity}</span>
                  </div>
                  <div>
                    <span><b>Dosis y frecuencia:</b> &nbsp;</span>
                    <span>{(drug.dose_free ? drug.dose_free : "Sin datos")}</span>
                  </div>
                </div>
                {arr.length === index + 1 ? '' : <hr />}
              </Grid>
          </Grid>
          )
      })
    })
    return aux
  })()

  const remoteData = async query => {
    const responseData = await getPrescriptionsPaging(query, params)
    const data = [...responseData.data]
    return {
        data: data,
        page: query.page,
        totalCount: responseData.total_items,
    };
  }

  return (
    <div style={{ width: "90%", marginTop: "16px" }}>
      <MaterialTable
        columns={columns}
        options={{
          maxBodyHeight: 400,
          header: true,
          actionsColumnIndex: -1,
          pageSize: 50,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [50, 100, 250, 500, 1000],
          paging: true,
          thirdSortClick: false,
          selection: false,
          rowStyle: (x, index) => {
            if (index % 2) {
              return { fontFamily: "roboto", fontSize: "small" }
            }
            return { fontFamily: "roboto", fontSize: "small", backgroundColor: '#EEEEEE', borderTopLeftRadius: '8px' }
          },
          search: false,
          sorting: true,
          draggable: false,
        }}
        title='Historial de recetas del paciente'
        data={remoteData}
        localization={{
          toolbar: {
              nRowsSelected: '{0} registro(s) seleccionado(s)',
              searchPlaceholder: 'DNI/Apellido del paciente',
              searchTooltip: 'Buscar por DNI o apellido del paciente'
          },
          header: {
              actions: ''
          },
          body: {
              emptyDataSourceMessage: 'No hay registros',
              filterRow: {
                  filterTooltip: 'Filtro'
              }
          },
          pagination: {
            labelRowsSelect: 'registros',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera página',
            previousTooltip: 'Anterior',
            nextTooltip: 'Siguiente',
            lastTooltip: 'Última página'
          }
      }}
      />
    </div>
  );
}

export default LastPrescriptionsTable