import React from 'react'
import { List, ListItem, ListItemText, Divider, ListItemIcon } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginTop: '16px',
    marginBottom: '8px',
    border: 'solid',
    borderWidth: 'thin',
    borderColor: 'rgba(0, 0, 0, 0.38)',
    borderRadius: '4px'
  },
  nested: {
    paddingLeft: '16px'
  }
}));

const RecordList = ({records}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <List>
        <ListItem>
          <ListItemIcon>
            <ListAltOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Historial Médico:" />
        </ListItem>
      </List>
      <Divider />
      <List className={classes.nested}>
        {
          records.map((record, index) => 
            <ListItem key={index}>
              <ListItemIcon>
                <LabelOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={record} />
            </ListItem>
            )
        }
        
      </List>

    </div>
  )
}

export default RecordList
