import React, { useContext } from 'react'
import { SideBarContext } from '../../App'
import logo from '../../img/logo.png'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core'
import PrescriptionsIcon from "../../svg/PrescriptionsIcon.svg"
import PrescriptionsSelectedIcon from "../../svg/PrescriptionsSelectedIcon.svg"
import CollapseMenuIcon from "../../svg/CollapseMenuIcon.svg"
import ExpandedMenuIcon from "../../svg/ExpandedMenuIcon.svg"
import LogoutIcon from "../../svg/LogoutIcon.svg"
import ReportsIcon from "../../svg/ReportsIcon.svg"
import ReportsSelectedIcon from "../../svg/ReportsSelectedIcon.svg"
import StadisticsIcon from "../../svg/StadisticsIcon.svg"
import StadisticsSelectedIcon from "../../svg/StadisticsSelectedIcon.svg"
import UsersManagementIcon from "../../svg/UsersManagementIcon.svg"
import UsersManagementSelectedIcon from "../../svg/UsersManagementSelectedIcon.svg"

const useStyles = makeStyles({
  navbar: {
    width: 344,
    position: 'relative',
    background: '#FFFFFF',
  },
  drawerPaper: {
    overflow: 'hidden',
    height: '100%',
    transition: 'all 0.3s'
  },
  drawerPaperCollapsed: {
    width: 84,
  },
  drawerPaperExpanded: {
    width: 344,
  },
  menuCollapsed: {
    padding: '0px 14px',
    transition: 'all 0.3s'
  },
  menuExpanded: {
    padding: '0px 32px',
    transition: 'all 0.3s'
  },
  options: {
    position: 'absolute',
    bottom: '40px',
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  logo: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '30px',
    width: 'auto',
    cursor: 'pointer'
  },
  root: {
    marginBottom: 20,
    "&$selected": {
      borderRadius: '8px',
      backgroundColor: "#3D4A5C",
      color: "white",
    },
    "&$selected:hover": {
      backgroundColor: "#3D4A5C",
      color: "white",
    },
    "&:hover": {
      backgroundColor: "#FFFFFF",
    }
  },
  selected: {},
});

export default function NavBar() {
  const classes = useStyles()
  const {isCollapsed, setIsCollapsed} = useContext(SideBarContext)
  return (
    <div>
      <Drawer anchor={'left'} open={true} variant="persistent"
        classes={{
          paper: `${classes.drawerPaper} ${isCollapsed ? classes.drawerPaperCollapsed : classes.drawerPaperExpanded}`,
        }}
        className={classes.navbar}>
      <div style={{width: '344px'}}>
        <div className={classes.logo}>
        <img src={logo} alt='Fleni' style={isCollapsed ? {visibility: 'hidden', transition: 'all 0.3s'} : {}} width="70%" onClick={() => window.location.replace("/appointments")} />
          
        </div>
        <div>
          <List className={isCollapsed ? classes.menuCollapsed : classes.menuExpanded}>
            <ListItem style={isCollapsed ? {width: 56, height: 48, transition: 'all 0.3s'} : {}} classes={{ root: classes.root, selected: classes.selected }} selected={window.location.href.includes('/appointments')} button component="a" href="/appointments" >
              <ListItemIcon style={{minWidth: '40px'}}>
                <Icon>
                  <img className={classes.imageIcon} src={window.location.href.includes('/appointments') ? PrescriptionsSelectedIcon : PrescriptionsIcon} alt='Listado de pedidos'/>
                </Icon>
              </ListItemIcon>
              <ListItemText primary={"Listado de pedidos"} style={isCollapsed ? {visibility: 'hidden'} : {}} />
            </ListItem>
            {localStorage.getItem('dashboard_profile')?.includes('admin') && 
            <div>
              <ListItem style={isCollapsed ? {width: 56, height: 48, transition: 'all 0.3s'} : {}} classes={{ root: classes.root, selected: classes.selected }} selected={window.location.href.includes('/users')} button component="a" href="/users" >
                <ListItemIcon style={{minWidth: '40px'}}>
                  <Icon>
                    <img className={classes.imageIcon} src={window.location.href.includes('/users') ? UsersManagementSelectedIcon : UsersManagementIcon} alt='Gestión de usuarios'/>
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={"Gestión de usuarios"} style={isCollapsed ? {visibility: 'hidden'} : {}} />
              </ListItem>
              <ListItem style={isCollapsed ? {width: 56, height: 48, transition: 'all 0.3s'} : {}} classes={{ root: classes.root, selected: classes.selected }} selected={window.location.href.includes('/roles')} button component="a" href="/roles" >
                <ListItemIcon style={{minWidth: '40px'}}>
                  <center>
                    <Icon>
                      {window.location.href.includes('/roles') ? <span style={{color: '#F59D00'}}>R</span> : <span>R</span>}
                    </Icon>
                  </center>
                </ListItemIcon>
                <ListItemText primary={"Gestión de roles"} style={isCollapsed ? {visibility: 'hidden'} : {}} />
              </ListItem>
              <ListItem style={isCollapsed ? {width: 56, height: 48, transition: 'all 0.3s'} : {}} classes={{ root: classes.root, selected: classes.selected }} selected={window.location.href.includes('/permissions')} button component="a" href="/permissions" >
                <ListItemIcon style={{minWidth: '40px'}}>
                  <center>
                    <Icon>
                      {window.location.href.includes('/permissions') ? <span style={{color: '#F59D00'}}>P</span> : <span>P</span>}
                    </Icon>
                  </center>
                </ListItemIcon>
                <ListItemText primary={"Gestión de permisos"} style={isCollapsed ? {visibility: 'hidden'} : {}} />
              </ListItem>
            </div>
            }
          </List>
        </div>
        <div className={`${classes.options} ${isCollapsed ? classes.menuCollapsed : classes.menuExpanded}`}>
          <List>
          <ListItem style={isCollapsed ? {width: 56, height: 48, transition: 'all 0.3s'} : {}} classes={{ root: classes.root }} button component="div" 
              onClick={() => {
                localStorage.clear()
                window.location.reload()
              }}>
              <ListItemIcon style={{minWidth: '40px'}}>
                <Icon>
                  <img className={classes.imageIcon} src={LogoutIcon} alt='Salir'/>
                </Icon>
              </ListItemIcon>
              <ListItemText primary={"Salir"} style={isCollapsed ? {visibility: 'hidden'} : {}} />
            </ListItem>
            <ListItem style={isCollapsed ? {width: 56, height: 48, transition: 'all 0.3s'} : {}} classes={{ root: classes.root }}button component="div" 
              onClick={() => {
                setIsCollapsed(!isCollapsed)
                localStorage.setItem('isSidebarCollapsed', !isCollapsed)
              }}>
              <ListItemIcon style={{minWidth: '40px'}}>
                <Icon>
                  { isCollapsed? 
                    <img className={classes.imageIcon} src={ExpandedMenuIcon} alt='Expander'/> :
                    <img className={classes.imageIcon} src={CollapseMenuIcon} alt='Colapsar'/> 
                  }
                </Icon>
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ style: { whiteSpace: "nowrap" } }} primary={"Colapsar menu"} style={isCollapsed ? {visibility: 'hidden'} : {}} />
            </ListItem>
          </List>
        </div>
    </div>
      </Drawer>
    </div>
  );
}
