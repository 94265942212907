import axios from 'axios'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const switchActivateUser = async (user) => {

  try {
    const url = `${REACT_APP_API_URL}/users/${user.id}/switch_active`
    const headers = {
      'Authorization': `Bearer ${await localStorage.getItem('dashboard_access_token')}`,
      'Content-Type': 'application/json'
    }
    const {data} = await axios.get(url, {headers: headers})
    return data;
  } catch (error) {
    
    return error;
  }
}


