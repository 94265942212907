import React, { useState } from 'react'
import { Grid, TextField, Dialog, DialogTitle, Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CategoriesList from './CategoriesList'
import { createPermission } from '../../services/permissions/createPermission'

const useStyles = makeStyles(() => ({
  dialog: {
    height: 'auto',
    width: '30%',
    padding: '2%',
    margin: 'auto'
  },
}));


const AddPermissionsDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const [newPermission, setNewPermission] = useState('')
  const [categoriesListData, setCategoriesListData] = useState([]);

  const handleClick = async () => {
    try {
      const data = await createPermission({name: newPermission, categories: categoriesListData})
      alert(`Permiso "${data.name}" creado correctamente`)
      window.location.reload()
    } catch (error) {
      alert('Hubo un error al crear el permiso')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      maxWidth="lg"
      classes={{
        paperWidthLg: classes.dialog, // class name, e.g. `classes-nesting-root-x`
      }}
    >
      <DialogTitle style={{padding: 0, marginBottom: '3%'}}>
        Crear Permiso
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          style={{left: '60%'}}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Grid container style={{gap: 15}}>
        <Grid item xs={12}>
          <TextField
            label="Nuevo permiso"
            value={newPermission}
            onChange={e => setNewPermission(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CategoriesList categoriesListData={categoriesListData} setCategoriesListData={setCategoriesListData} />
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{float: 'right', backgroundColor: '#F59D00'}}
            onClick={handleClick}
            disabled={newPermission === '' || categoriesListData.length === 0}
          >
            CREAR PERMISO
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default AddPermissionsDialog