/* eslint-disable no-extend-native */
import React, { useEffect, createRef } from 'react'
import MaterialTable, { MTableBodyRow } from '@material-table/core'
import {  Grid, Typography } from '@material-ui/core'
import { getAppointmentsPaging } from '../../services/appointments/getAppointmentsPaging'
import { TableHeader } from './TableHeader'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LaunchIcon from '@material-ui/icons/Launch';
import { processAppointment } from '../../services/appointments/processAppointment'


String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}


const AppointmentsTable = ({params, handleClickOpen}) => {
  const tableRef = createRef()

  const columns = (() => {
    let aux = []
    aux.push({ title: "Nº", field: "order_number", type: "numeric", editable: false, search: true, headerStyle: {textAlign: 'center'},})
    aux.push({ 
      field: "created", 
      title: 'Fecha de solicitud', 
      headerStyle: {textAlign: 'center'},
      editable: false,
      search: true,
      sorting: true,
      render: rowData => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit' }
        const date = new Date(rowData.created * 1000).toLocaleDateString('es-ES', options).split(',')
        return <center>{date[0]}<br />{date[1]}</center>
      }
    })
    aux.push({ 
      field: "user.document", 
      title: 'DNI',
      sorting: true,
      search: true,
      headerStyle: {display: 'none'},
      cellStyle: {display: 'none'},
    })
    aux.push({
      title: "Paciente",
      width: '20%',
      field: "user.name",
      headerStyle: {textAlign: 'left'},
      sorting: false,
      search: true,
      customFilterAndSearch: (term, rowData) => (rowData.user.lastname + ' ' + rowData.user.name).toLowerCase().indexOf(term.toLowerCase()) !== -1,
      render: rowData => {
        return (
          <Grid container>
            <Grid item xs={12}><b>Nombre:</b> {rowData.user?.name} </Grid>
            <Grid item xs={12}><b>Telefono:</b> {rowData.user?.user_id.replace('WB_549', '')} </Grid>
            <Grid item xs={12}><b>Edad:</b> {rowData.user?.age} </Grid>
            <Grid item xs={12}><b>DNI:</b> {rowData.user?.document.split('_')[1]} </Grid>
            <Grid item xs={12}><b>Email:</b> {rowData.user?.email} </Grid>
            <Grid item xs={12}><b>Obra Social:</b> {rowData.user?.aco} </Grid>
            <Grid item xs={12}><b>Nº Obra Social:</b> {rowData.user?.aco_number} </Grid>
          </Grid>
        )
      },
      editable: false
    })
    aux.push({
      title: "Consulta",
      field: "subject",
      search: false,
      editable: false,
      sorting: false,
      render: rowData => {
        return (
          <Grid container>
            <Grid item xs={12}><b>Titulo:</b> {rowData.title} </Grid>
            <Grid item xs={12}><b>Consulta:</b> {rowData.subject} </Grid>
            <Grid item xs={12}><b>Categoría:</b> {rowData.category} </Grid>
          </Grid>
        )
      },
    })
    aux.push({
      title: "Resumen",
      width: '30%',
      field: "summary",
      headerStyle: {textAlign: 'left'},
      sorting: false,
      search: false,
      editable: false,
      render: rowData => {
        return (
          <div dangerouslySetInnerHTML={{ __html: rowData.summary }} />
        )
      },
    })
    return aux
  })()

  useEffect(() => {
    const timer = setInterval(() => {
      tableRef.current && tableRef.current.onQueryChange()
    }, (5 * 60 * 1000))
    
    return () => {
      clearInterval(timer);
    }
  }, [tableRef])


  const remoteData = async query => {
    const responseData = await getAppointmentsPaging(query, params)
    const data = [...responseData.data]
    return {
        data: data,
        page: query.page,
        totalCount: responseData.total_items,
    };
  }

  return (
    <div style={{padding: '20px'}}>
      <TableHeader tableRef={tableRef} params={params} />
      <MaterialTable
        title={'Listado de pedidos'}
        tableRef={tableRef}
        columns={columns}
        options={{
          headerStyle: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            fontWeight: 'bold',
            fontSize: 'small',
          },
          showTextRowsSelected: false,
          selectionProps: rowData => ({
            disabled: rowData.processed === true,
            color: 'primary'
          }),
          actionsColumnIndex: -1,
          pageSize: 50,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [50, 100, 250, 500, 1000],
          paging: true,
          showTitle: true,
          thirdSortClick: false,
          selection: true,
          grouping: false,
          rowStyle: (x, index) => {
            if (index % 2) {
              return { fontFamily: "roboto", fontSize: "small" }
            }
            return { fontFamily: "roboto", fontSize: "small", backgroundColor: '#EEEEEE', borderTopLeftRadius: '8px' }
          },
          search: true,
          searchFieldVariant: 'outlined',
          draggable: false,
        }}
        data={remoteData}
        actions={[
          {
            tooltip: 'Abrir pedido',
            icon: () => <LaunchIcon />,
            onClick: (evt, data) => handleClickOpen(data.id),
            position: 'row',
          },
          {
            name: "process",
            tooltip: 'Cambiar estado del pedido',
            icon: () => <CheckBoxOutlineBlankIcon />,
            onClick: (evt, data) => {
              if (window.confirm(`Esta por cambiar el estado del pedido Nº: ${data.order_number}`)){
                (async function() {
                  await processAppointment({...data},)
                  tableRef.current.onQueryChange()
                })();
              }
            },
            position: 'row',
            
          },
        ]}
        
        components={{
          Row: props => {
            const propsCopy = { ...props };
            propsCopy.actions.find(a => a.name === 'process').icon = propsCopy.data.processed ? () => <CheckBoxIcon /> : () => <CheckBoxOutlineBlankIcon />
            return <MTableBodyRow {...propsCopy} />
          },
          Toolbar: props => (
            <></>
          ),

        }}
        localization={{
          toolbar: {
              nRowsSelected: '{0} registro(s) seleccionado(s)',
              searchPlaceholder: 'DNI/Apellido del paciente',
              searchTooltip: 'Buscar por DNI o apellido del paciente'
          },
          header: {
              actions: ''
          },
          body: {
              emptyDataSourceMessage: 'No hay registros',
              filterRow: {
                  filterTooltip: 'Filtro'
              }
          },
          pagination: {
            labelRowsSelect: 'registros',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera página',
            previousTooltip: 'Anterior',
            nextTooltip: 'Siguiente',
            lastTooltip: 'Última página'
          }
      }}
      />
    </div>
  );
}

export default AppointmentsTable