import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginLeft: '40%',
  },
  paper: {
    minWidth: '40%',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

export default function Unauthorized() {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <Grid container >
          <Paper className={classes.paper}>
            No tiene permisos para visualizar la página
          </Paper>
        </Grid>
      </div>
    </>
  );
}
