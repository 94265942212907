import axios from 'axios';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const getAppointment = async (id) => {

  try {
    const url = `${REACT_APP_API_URL}/appointments/${id}`
    const headers = {
      'Authorization': `Bearer ${await localStorage.getItem('dashboard_access_token')}`,
      'Content-Type': 'application/json'
    };
    const response = await axios.get(url,{headers: headers})
    return response
  } catch (error) {
    error.status = 500
    return error
  }
}


