import React, { useState } from 'react'
import  { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Button, Grid, Paper, CircularProgress } from '@material-ui/core'
import { directLogin } from '../services/login/directLogin'
import { validateSession } from '../utils'
import logo from "../img/logo.png"

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginTop: '10%',
  },
  paper: {
    width: "20%",
    borderRadius: "3%",
    boxShadow: "0 2px 5px",
    margin: "0 auto",
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function DirectLogin() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  function handleLogin() {
    setLoading(true)
    if (username && password){
      directLogin({'username':username, 'password':password}).then(({data, status}) => {
        if (status === 401) {
          alert('El nombre de usuario o contraseña es incorrecto');
          setLoading(false)
          setPassword('');
        } else {
          let dt = new Date()
          dt.setMinutes(dt.getMinutes() + 30)
          let expire = new Date(dt).getTime()
          localStorage.setItem('dashboard_access_token', data.access_token);
          localStorage.setItem('dashboard_refresh_token', data.refresh_token);
          localStorage.setItem('dashboard_expire', expire);
          localStorage.setItem('dashboard_profile', data.profile);
          localStorage.setItem('dashboard_user_id', data.user_id);
          localStorage.setItem('dashboard_logged_user', username);
          window.location.replace('/appointments')
          setUsername('');
          setPassword('');
          setLoading(false)
          
        };
      }).catch(
        (e) => {
          alert('Hubo un problema al intentar iniciar sesión.');
          setPassword('');
          setLoading(false)
        }
      );
    } else{
      alert('Ingrese usuario y/o contraseña')
      setLoading(false)
    }
  }

  if (validateSession()) {
    return <Redirect to={'appointments'} />
  }

  return (
    <div className={classes.root}>
      <Grid container >
        <Paper className={classes.paper}>
        <Grid item xs={12}>
            <img src={logo} alt='Fleni' width="100%" />
          </Grid>
          <Grid item xs={12}>
            <TextField name="username" label="Usuario" variant="outlined" fullWidth style={{marginTop: '12px'}} autoComplete='off' value={username} onChange={({target}) => setUsername(target.value)}/>
          </Grid>
          <Grid item xs={12}>
            <TextField name="password" label="Contraseña" variant="outlined" type="password" fullWidth style={{marginTop: '12px'}} value={password} onChange={({target}) => setPassword(target.value)}/>
          </Grid>
          <Grid item xs={12} style={{position: 'relative'}}>
            {loading && <CircularProgress style={{marginTop: '12px', marginLeft: '44%'}}/>}
            {!loading && <Button variant="contained" color="primary" style={{marginTop: '12px', width: '100%', backgroundColor: '#F59D00'}} onClick={handleLogin}>
              INICIAR SESIÓN
            </Button>}
          </Grid>
          <Grid item xs={12}>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}