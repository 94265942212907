import axios from 'axios'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const getPermissions = async () => {

  try {
    const url = `${REACT_APP_API_URL}/permissions`
    const headers = {
      'Authorization': `Bearer ${await localStorage.getItem('dashboard_access_token')}`,
      'Content-Type': 'application/json'
    };
    const {data} = await axios.get(url, {headers: headers});
    return data;
  } catch (error) {
    throw error;
  }
}


