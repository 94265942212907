import React from 'react';
import addUser from "../../svg/AddUser.svg"

function AddUserIcon() {
  return (
    <>
      <div>
        <img src={addUser} alt=''/>
      </div>
    </>
  );
}
export default AddUserIcon