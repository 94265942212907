import axios from 'axios'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const getAllRoles = async () => {

  try {
    const url = `${REACT_APP_API_URL}/roles`
    const headers = {
      'Authorization': `Bearer ${await localStorage.getItem('dashboard_access_token')}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    const response = await axios.get(url,{headers: headers})
    return response.data;
  } catch (error) {
    throw error
  }
}


