import axios from 'axios'
import flatten from 'flat';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit' };

export const filterPrescriptions = async (query) => {

  try {
    const url = `${REACT_APP_API_URL}/prescriptions_query?${query}`;
    const headers = {
      'Authorization': `Bearer ${await localStorage.getItem('dashboard_access_token')}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    const {data} = await axios.get(url,{headers: headers})
    return data.map(e => {
      let castObject = {...flatten(e), created: new Date(e.created * 1000).toLocaleDateString('es-ES', options), modified: new Date(e.modified * 1000).toLocaleDateString('es-ES', options)}
      return JSON.parse(JSON.stringify(castObject, (k, v) => v && typeof v === 'object' ? v : '' + v))
    })
  } catch (error) {
    return error;
  }
}


