import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { Grid, IconButton, MenuItem, Select } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import categoriesRoles from '../../dictionaries/categoriesRoles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function CategoriesList({categoriesListData, setCategoriesListData}) {
  const classes = useStyles();
  const [categorySelected, setCategorySelected] = useState('');

  const handleDelete = (categoryToDelete) => () => {
    setCategoriesListData(categoriesListData.filter(category => category !== categoryToDelete));
  };

  const handleAdd = () => {
    if (categoriesListData.includes(categorySelected)){
      alert('La categoria ya fue añadida')
    } else {
      setCategoriesListData([...categoriesListData, categorySelected])
    }
  }

  return (
    
      <Grid container style={{gap: 15}}>
        <Grid item xs={12}>
          <Select
            style={{width: '87%'}}
            fullWidth
            displayEmpty
            variant='outlined'
            value={categorySelected}
            onChange={(event) =>
              setCategorySelected(event.target.value)
            }
          >
            <MenuItem value={''}>Seleccionar categoria</MenuItem>
            {categoriesRoles.map((category, index) => <MenuItem key={index} value={category}>{category}</MenuItem>)}
          </Select>
          <IconButton  onClick={handleAdd}>
            <AddIcon />
          </IconButton>
        </Grid>
        {categoriesListData.length !== 0 && <Grid item xs={12} style={{gap: 15}}>
          <Paper component="ul" className={classes.root}>
            {categoriesListData.map((category, index) => {
            return (
                <li key={index}>
                  <Chip
                    label={`${category}`}
                    onDelete={handleDelete(category)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Paper>
        </Grid>}
      </Grid>
    
  );
}