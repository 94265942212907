import axios from 'axios'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const getAppointmentsPaging = async (query, params = '') => {
  const page = query.page
  const pageSize = query.pageSize
  let moreParams = ''
  if(params){
    moreParams = moreParams + `&${params}`
  }
  let order = ''
  if (query.orderBy?.field){
    if(query.orderDirection === 'asc'){
      order = `&order_by=${query.orderBy.field}`
    }else {
      order = `&order_by=-${query.orderBy.field}`
    }
  }
  try {
    const url = `${REACT_APP_API_URL}/appointments?page_number=${page + 1}&page_size=${pageSize}${moreParams}${order}`
    const headers = {
      'Authorization': `Bearer ${await localStorage.getItem('dashboard_access_token')}`,
      'Content-Type': 'application/json'
    };
    const {data} = await axios.get(url,{headers: headers})
    if (data.next == null) {
      data.next = data.total_pages 
    }
    return data;
  } catch (error) {
    return error;
  }
}


