import React, { useContext } from 'react'
import UsersManagementTable from '../components/UsersManagement/UsersManagementTable'
import { SideBarContext } from '../App'

export default function UsersManagement() {
  const {isCollapsed} = useContext(SideBarContext)

  return (
    <div style={isCollapsed ? {marginLeft: '84px', transition: 'all 0.3s'} : {marginLeft: '344px', transition: 'all 0.3s'}}>
      <UsersManagementTable />
    </div>
  );
}
