import React, { useState, createContext } from 'react'
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom"
import Prescriptions from './pages/Prescriptions'
import Prescription from './pages/Prescription'
import Unauthorized from './pages/Unauthorized'
import Login from './pages/Login'
import DirectLogin from './pages/DirectLogin'
import UsersManagement from './pages/UsersManagement'
import Export from './pages/Export'
import Dashboard from './pages/Dashboard'
import { PrivateRoute } from './components/PrivateRoute'
import { ProtectedRoute } from './components/ProtectedRoute'
import Appointments from './pages/Appointments'
import Appointment from './pages/Appointment'
import Roles from './pages/Roles'
import Permissions from './pages/Permissions'
export const SideBarContext = createContext()

const App = () => {
  const sidebarCollapsed = localStorage.getItem('isSidebarCollapsed')
  const [isCollapsed, setIsCollapsed] = useState(sidebarCollapsed === 'true')
  const redirectUrl = window.location.href.replace(window.location.origin, '')
  return (
    <SideBarContext.Provider value={{
      isCollapsed,
      setIsCollapsed
    }}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" ><Redirect to="/login" /> </Route>
          <Route exact path="/login" render={() => <DirectLogin />} /> 
          <Route
            exact path="/appointments"
            render={() =>
              <ProtectedRoute>
                <Appointments />
              </ProtectedRoute>
            }
          />
          <Route exact path="/appointments/:id"
            render={(props) =>
              <ProtectedRoute>
                <Appointment {...props} />
              </ProtectedRoute>
            }
          />
          <Route exact path="/users" render={() =>
            <ProtectedRoute>
              <PrivateRoute>
                <UsersManagement />
              </PrivateRoute>
            </ProtectedRoute>}
          />
          <Route exact path="/roles" render={() =>
            <ProtectedRoute>
              <PrivateRoute>
                <Roles />
              </PrivateRoute>
            </ProtectedRoute>}
          />
          <Route exact path="/permissions" render={() =>
            <ProtectedRoute>
              <PrivateRoute>
                <Permissions />
              </PrivateRoute>
            </ProtectedRoute>}
          />
          <Route exact path="/export" render={() =>
            <ProtectedRoute>
              <PrivateRoute>
                <Export />
              </PrivateRoute>
            </ProtectedRoute>}
          />
          <Route exact path="/metabase" render={() =>
            <ProtectedRoute>
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            </ProtectedRoute>}
          />
          <Route exact path="/unauthorized"
            render={() =>
              <ProtectedRoute>
                <Unauthorized />
              </ProtectedRoute>
            } 
          />
          <Route component={() => (<div>404 Not found </div>)} />
        </Switch>
      </BrowserRouter>
    </SideBarContext.Provider>
  )
}

export default App