import React, { useContext } from 'react'
import RolesTable from '../components/Roles/RolesTable'
import { SideBarContext } from '../App'

export default function Permissions() {

  const {isCollapsed} = useContext(SideBarContext)

  return (
    <div style={isCollapsed ? {marginLeft: '84px', transition: 'all 0.3s'} : {marginLeft: '344px', transition: 'all 0.3s'}}>
      <RolesTable />
    </div>
  );
}
