import axios from 'axios'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export const createRole = async (role) => {

  try {
    const url = `${REACT_APP_API_URL}/roles`
    const headers = {
      'Authorization': `Bearer ${await localStorage.getItem('dashboard_access_token')}`,
      'Content-Type': 'application/json'
    };
    const {data} = await axios.post(url, {...role}, {headers: headers});
    return data;
  } catch (error) {
    throw error;
  }
}


