/* eslint-disable no-extend-native */
import React, { useEffect, createRef, useState } from 'react'
import MaterialTable from '@material-table/core'
import { Tooltip, Grid, Typography, Chip, Avatar } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import SelectDoctor from './SelectDoctor'
import complexityDictionary from '../../dictionaries/complexity.json'
import { getPrescriptionsPaging } from '../../services/prescriptions/getPrescriptionsPaging'
import EmbarazoIcon from '../Icons/PregnantIcon'
import LactancyIcon from '../Icons/LactancyIcon'
import { TableHeader } from './TableHeader'
import { EditPrescriptions } from './EditPrescriptions'
import { getUsersAssignedToday } from '../../services/users/getUsersAssignedToday';
import CriticalyIcon from '../Icons/Criticaly'

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

const formatName = (assigned) => {
  let displayname = ''
  if (assigned.name && assigned.lastname) {
    displayname = `${assigned.lastname.toLowerCase().charAt(0).toUpperCase() + assigned.lastname.toLowerCase().slice(1)}, ${assigned.name.toLowerCase().charAt(0).toUpperCase() + assigned.name.toLowerCase().slice(1)}`
  }
  return displayname
}

const PrescriptionsTable = ({params, handleClickOpen}) => {
  const tableRef = createRef()
  const [selectedRows, setSelectedRows] = useState([])
  const [assignedToday, setAssignedToday] = useState({})

  const getAssignedToday = async () => {
    try {
      const responseData = await getUsersAssignedToday()
      setAssignedToday({...responseData})
    } catch (error) {
      console.log(error)
      alert('Hubo un error al intentar buscar las recetas asignadas a los usuarios en el día de hoy')
    }
  }

  const columns = (() => {
    let aux = []
    aux.push({ title: "Nº Orden", field: "order_number", type: "numeric", editable: false, search: true, headerStyle: {textAlign: 'center'},})
    aux.push({ 
      field: "created", 
      title: 'Fecha de solicitud', 
      headerStyle: {textAlign: 'center'},
      editable: false,
      search: true,
      sorting: true,
      render: rowData => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit' }
        const date = new Date(rowData.created * 1000).toLocaleDateString('es-ES', options).split(',')
        return <center>{date[0]}<br />{date[1]}</center>
      }
    })
    aux.push({ 
      field: "user.dni", 
      title: 'SMG ID',
      sorting: true,
      search: true,
      headerStyle: {display: 'none'},
      cellStyle: {display: 'none'},
    })
    aux.push({
      title: "Paciente",
      width: '15%',
      field: "user.lastname",
      headerStyle: {textAlign: 'left'},
      sorting: false,
      search: true,
      customFilterAndSearch: (term, rowData) => (rowData.user.lastname + ' ' + rowData.user.name).toLowerCase().indexOf(term.toLowerCase()) !== -1,
      render: rowData => {
        return (
          <Grid container>
            {rowData.medical_history?.join().includes('Embarazo') ? <Grid item xs={12} style={{marginBottom: '-10%'}}><EmbarazoIcon /></Grid> : ''}
            {rowData.medical_history?.join().includes('Puerperio') ? <Grid item xs={12} style={{marginBottom: '-10%'}}><LactancyIcon /></Grid> : ''}
            <Grid item xs={12}><b>Nombre:</b> {rowData.user.lastname}, {rowData.user.name}</Grid>
            <Grid item xs={12}><b>Telefono:</b> {rowData.user.user_id.replace('WB_549', '')}</Grid>
            <Grid item xs={12}><b>Edad:</b> {rowData.user.age}</Grid>
            <Grid item xs={12}><b>DNI:</b> {rowData.user.dni}</Grid>
            <Grid item xs={12}><b>SMG:</b> {rowData.user.smg_str ? rowData.user.smg_str : rowData.user.smg}</Grid>
          </Grid>
        )
      },
      editable: false
    })
    if (localStorage.getItem('dashboard_profile') !== 'auxiliar'){
      aux.push({
        title: "Complejidad",
        field: "complexity.title",
        search: false,
        editable: false,
        sorting: false,
        render: rowData => {
          const complexityAux = complexityDictionary.find(e => e.complexity === rowData.complexity.title)
          return (
            <Tooltip 
              placement="top"
              arrow
              title={
                complexityAux &&
                <div>
                  {complexityAux && <div style={{ fontSize: "small", margin: "6px 0" }}>&bull; {complexityAux?.description}</div>}
                  {complexityAux && <div style={{ fontSize: "small", margin: "6px 0" }}>&bull; {complexityAux?.approach}</div>}
                </div>
              }
            >
              <div><CriticalyIcon criticaly={rowData.complexity.title} /></div>
            </Tooltip>
            )
        }
      })
      aux.push({
        title: "Lista de medicaciones",
        field: "drugs",
        editable: false,
        sorting: false,
        search: false,
        render: rowData => rowData.drugs.map((drug, index, arr) => {
          return (
              <Grid container direction="row" alignItems="center" key={index}>
                <Grid item xs={1} style={{ marginTop: '2%' }}>
                  {(drug.allergy !== null && drug.allergy !== 'No.') && 
                  <Tooltip arrow placement="top"  style={{ fontSize: "medium" }} title={drug.allergy}>
                    <WarningIcon />
                  </Tooltip>}
                </Grid>
                <Grid item xs={11}>
                  <div style={{ paddingLeft: '5%' }}>
                    <div>
                      <Tooltip arrow placement="left"  style={{ fontSize: "small" }} title={`Posología: ${drug.dose_free ? drug.dose_free : 'No hay datos' }`}>
                        <span>{`${drug.commercial_name?.capitalize()} (${drug.name?.capitalize()}, ${drug.presentation?.capitalize()}) - ${drug.classification ? drug.classification : ''}`}</span>
                      </Tooltip>
                    </div>
                    <div>
                      <span><b>Envases:</b> &nbsp;</span>
                      <span>{drug.quantity}</span>
                    </div>
                  </div>
                  {arr.length === index + 1 ? '' : <hr />}
                </Grid>
            </Grid>
            )
        })
      })
    } else {
      aux.push({
        title: "Categorias",
        field: "drugs",
        editable: false,
        sorting: false,
        search: false,
        render: rowData => rowData.drugs.map((drug, index, arr) => {
          return (
              <Grid container direction="row" alignItems="center" key={index}>
                <Grid item xs={11}>
                  <div>
                    <li style={{whiteSpace: 'nowrap'}}>
                      <span>{drug.classification ? drug.classification : <b>-</b>}</span>
                    </li>
                  </div>
                </Grid>
            </Grid>
            )
        })
      })
    }
    aux.push({ 
      title: "Médico asignado", 
      field: "assigned_id",
      filtering: false,
      sorting: false,
      search: true,
      render: rowData => {
        if (rowData.assigned) {
          return (
            <center>
              <Tooltip arrow placement="top"  title={'Recetas asignadas el día de hoy'}>
                <Chip 
                  clickable
                  variant="outlined"
                  component="a"
                  target='_blank'
                  style={{height: '100%', padding: '1%'}}
                  href={`assigned/${rowData.assigned_id}`}
                  avatar={<Avatar>{assignedToday[rowData.assigned.id] !== undefined ? assignedToday[rowData.assigned.id] : 0 }</Avatar>}
                  label={<Typography style={{whiteSpace: 'normal'}}>{`${formatName(rowData.assigned)}` || rowData.assigned?.username}</Typography>}
                />
              </Tooltip>
            </center>
          )
        } else {
          return <b><center>-</center></b>
        }
        
      },
      editComponent: (props) => (
        <SelectDoctor 
          value={props.value}
          onChange={(event) =>
            props.onChange(event.target.value)
        }
        />),
    })
    return aux
  })()

  useEffect(() => {
    const timer = setInterval(() => {
      getAssignedToday()
      tableRef.current && tableRef.current.onQueryChange()
    }, (5 * 60 * 1000))
    
    return () => {
      clearInterval(timer);
    }
  }, [tableRef])

  useEffect(() => {

    getAssignedToday()

    return () => {
    }
  }, [])

  const remoteData = async query => {
    const responseData = await getPrescriptionsPaging(query, params)
    const data = [...responseData.data]
    return {
        data: data,
        page: query.page,
        totalCount: responseData.total_items,
    };
  }

  return (
    <div style={{padding: '20px'}}>
      <TableHeader tableRef={tableRef} params={params} getAssignedToday={getAssignedToday} />
      {selectedRows.length ? <EditPrescriptions getAssignedToday={getAssignedToday} tableRef={tableRef} setSelectedRows={setSelectedRows} selectedRows={selectedRows} /> : null}
      <MaterialTable
        title={'Listado de recetas'}
        tableRef={tableRef}
        columns={columns}
        onSelectionChange={(rows) =>  setSelectedRows(rows) }
        options={{
          headerStyle: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            fontWeight: 'bold',
            fontSize: 'small',
          },
          showTextRowsSelected: false,
          selectionProps: rowData => ({
            disabled: rowData.processed === true,
            color: 'primary'
          }),
          maxBodyHeight: selectedRows.length ? '70vh' : '80vh',
          actionsColumnIndex: -1,
          pageSize: 50,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [50, 100, 250, 500, 1000],
          paging: true,
          showTitle: true,
          thirdSortClick: false,
          selection: true,
          grouping: false,
          rowStyle: (x, index) => {
            if (index % 2) {
              return { fontFamily: "roboto", fontSize: "small" }
            }
            return { fontFamily: "roboto", fontSize: "small", backgroundColor: '#EEEEEE', borderTopLeftRadius: '8px' }
          },
          search: true,
          searchFieldVariant: 'outlined',
          draggable: false,
        }}
        data={remoteData}
        actions={[
          {
            tooltip: 'Abrir Receta',
            icon: 'Ver Receta',
            hidden: localStorage.getItem('dashboard_profile') === 'auxiliar',
            onClick: (evt, data) => handleClickOpen(data.id),
            position: 'row',
          }
        ]}
        components={{
          Toolbar: props => (
            <></>
          ),
          Action: props => (
            <Typography
              onClick={(event) => props.action.onClick(event, props.data)}
              color="primary"
              variant="contained"
              align='center'
              style={{textTransform: 'none', cursor: 'pointer'}}
              size="small"
              component='a'
            >
              Ver Receta
            </Typography>
          ),
        }}
        localization={{
          toolbar: {
              nRowsSelected: '{0} registro(s) seleccionado(s)',
              searchPlaceholder: 'DNI/Apellido del paciente',
              searchTooltip: 'Buscar por DNI o apellido del paciente'
          },
          header: {
              actions: ''
          },
          body: {
              emptyDataSourceMessage: 'No hay registros',
              filterRow: {
                  filterTooltip: 'Filtro'
              }
          },
          pagination: {
            labelRowsSelect: 'registros',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera página',
            previousTooltip: 'Anterior',
            nextTooltip: 'Siguiente',
            lastTooltip: 'Última página'
          }
      }}
      />
    </div>
  );
}

export default PrescriptionsTable