import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import { Button, Paper } from '@material-ui/core'
import AddRolesDialog from './AddRolesDialog'
import { getAllRoles } from '../../services/roles/getAllRoles'

export default function RolesTable() {
  const [roles, setRoles] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const handleClickOpen = () => {
    setOpenDialog(true)
  };

  const handleClose = () => {
    setOpenDialog(false)
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const data = await getAllRoles()
        setRoles(data)
      } catch (error) {
        alert('Error al traer los roles')
      }
    }
    fetchRoles()
    return () => {
      
    }
  }, [])

  return (
    <div style={{padding: '20px'}}>
      <AddRolesDialog open={openDialog} handleClose={handleClose} />
      <MaterialTable
        columns={[
          { 
            title: "id", 
            field: "id", 
            type: "string",
            hidden: true,
            editable: false,
            searchable: false,
          },
          { 
            title: "Nombre del rol",
            field: "name", 
            type: "string",
            width: "20%",
            editable: false,
            searchable: true,
          },
          { 
            title: "Permisos", 
            field: "permissions",
            width: "80%",
            render: rowData => rowData.permissions.join(' | ')
          },
        ]}
        title={'Roles'}
        options={{
          headerStyle: {
            position: 'sticky',
            top: 0,
            zIndex: 1
          },
          minbodyHeight: "85vh",
          maxBodyHeight: '85vh',
          actionsColumnIndex: -1,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          search: true,
          searchFieldVariant: 'outlined',
          searchFieldStyle: {height: '40px'},
          paging: true,
          sorting: false,
          editable: false,
          rowStyle: (x, index) => {
            if (index % 2) {
              return { fontFamily: "roboto", fontSize: "small" }
            }
            return { fontFamily: "roboto", fontSize: "small", backgroundColor: '#EEEEEE', borderTopLeftRadius: '8px' }
          },
        }}
        data={roles}
        components={{
          Container: props => <Paper {...props} elevation={0}/>,
          Toolbar: (props) => (
            <div
              style={{
                backgroundColor: '#EEEEEE',
                paddingBottom: '15px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{width: '85%', display: 'inline-block'}}>
                <MTableToolbar {...props} />
              </div>
              <div style={{width: '20%', display: 'inline-block'}}>
                <Button
                  variant="contained"
                  size='small'
                  disableElevation
                  style={{backgroundColor: '#F59D00', height: '40px', float: 'right',  marginRight: '20%'}}
                  onClick={() => handleClickOpen()}
                >
                  CREAR NUEVO ROL
                </Button>
              </div>
            </div>
          )
        }}
        localization={{
          toolbar: {
              nRowsSelected: '{0} registro(s) seleccionado(s)',
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar'
          },
          header: {
              actions: 'Acciones'
          },
          body: {
              emptyDataSourceMessage: 'No hay roles',
              filterRow: {
                  filterTooltip: 'Filtro'
              }
          },
          pagination: {
            labelRowsSelect: 'roles',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera página',
            previousTooltip: 'Anterior',
            nextTooltip: 'Siguiente',
            lastTooltip: 'Última página'
          }
        }}
      />
    </div>
  );
}
