import React from 'react'
import { Redirect } from "react-router-dom"
import NavBar from './NavBar/NavBar'

export const PrivateRoute = ({ children }) => {
  return localStorage.getItem('dashboard_logged_user')?.includes('admin') ? (
    <div>
      <NavBar />
      {children}
    </div>
  ) : (
    <Redirect
      to={{
        pathname: "/unauthorized"
      }}
    />
  );
};