import React from 'react';
import lactancia from "../../svg/lactancia.svg"
import { Tooltip } from '@material-ui/core';

function LactancyIcon() {
  return (
    <>
      <div style={{ display:'inline-block', width:'25%', margin: '0 0 -35% -30%'}}>
        <Tooltip arrow placement="top" title='Paciente en periodo de lactancia' style={{ fontSize: "medium" }} >
          <img src={lactancia} alt='' style={{display: 'block',  width: '150%'}}/>
        </Tooltip>
      </div>
    </>
  );
}
export default LactancyIcon