import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PrescriptionContent from './PrescriptionContent';

const useStyles = makeStyles(() => ({
  dialog: {
    height: '90%',
    width: '100%',
  },
}));

const DialogPrescription = ({open, handleClose, prescriptionId}) => {
  const classes = useStyles();

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      classes={{
        paperWidthLg: classes.dialog,
      }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent>
        <PrescriptionContent match={{params: {id: prescriptionId}}} handleCloseDialog={handleClose}/>
      </DialogContent>
    </Dialog>
  )
}

export default DialogPrescription
