import React, { useState, useEffect } from 'react'
import { Grid, IconButton, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { getUsersToAssign } from '../../services/users/getUsersToAssign';
import { updatePrescription } from '../../services/prescriptions/updatePrescription';
import { getUserProfile } from '../../utils';
import ConfirmModal from '../Modals/ConfirmModal';
import CancelModal from '../Modals/CancelModal';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginBottom: '22px',
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    border: '1px solid #F59D00',
    boxShadow: '0px 4px 20px rgba(174, 196, 205, 0.25)',
    borderRadius: '12px'
  },
}))

export const EditPrescriptions = ({selectedRows, setSelectedRows, tableRef, getAssignedToday}) => {
  const classes = useStyles()
  const [users, setUsers] = useState([])
  const [doctorToAssign, setDoctorToAssign] = useState('')
  const [prescriptionStatus, setPrescriptionStatus] = useState('')
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const handleConfirmModalOpen = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
  };

  const handleCancelEdit = () => {
    tableRef.current.onAllSelected(false)
    setSelectedRows([])
    tableRef.current && tableRef.current.onQueryChange()
  }

  const handleUpdatePrescription = async () => {
    for (const element of selectedRows) {
      try {
        await updatePrescription({id: element.id, assigned_id: doctorToAssign, processed: prescriptionStatus === 'processed' ? true : false})
      } catch (error) {
        alert('Hubo un error al procesar la receta Nº ' + element.order_number)
      }
    }
    tableRef.current && tableRef.current.onQueryChange()
    tableRef.current.onAllSelected(false)
    setSelectedRows([])
    getAssignedToday()
  }

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await getUsersToAssign()
        setUsers([...users])
      } catch (error) {
        alert('Error al traer los usuarios')
      }
    }
    fetchUsers()
    return () => {
      
    }
  }, [])

  return (
    <div className={classes.root}>
      <ConfirmModal open={confirmModalOpen} handleClose={handleConfirmModalClose} callToAction={handleUpdatePrescription} />
      <CancelModal open={cancelModalOpen} handleClose={handleCancelModalClose} callToAction={handleCancelEdit} />
      <Paper className={classes.paper}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography variant='h5' >Editar {selectedRows.length} receta/s</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              style={{float: 'right', width:'80%'}}
              size='small'
              variant='outlined'
              value={doctorToAssign}
              label='Médico asignado'
              onChange={(e) => setDoctorToAssign(e.target.value)}
              disabled={getUserProfile() === 'doctor'}
            >
              {users.map(e => <MenuItem key={e.id} value={e.id}>{e.name.replace('@swissmedical.com.ar', '')}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              style={{float: 'right', width:'80%'}}
              size='small'
              variant='outlined'
              value={prescriptionStatus}
              label='Estado de la receta'
              onChange={(e) => setPrescriptionStatus(e.target.value)}
              disabled={getUserProfile() === 'auxiliar'}
            >
              <MenuItem value={'processed'}>Procesada</MenuItem>
              <MenuItem value={'unprocessed'}>No procesada</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <div style={{float: 'right', width: '80%'}}>
              <IconButton onClick={handleConfirmModalOpen}>
                <CheckCircle style={{fill: 'green'}} />
              </IconButton>
              <IconButton >
                <Cancel style={{fill: 'red'}} onClick={handleCancelModalOpen} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
