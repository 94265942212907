import React, { useState, useEffect } from 'react'
import { Redirect } from "react-router-dom"
import { getIframeUrl } from '../services/getIframeUrl'
import { validateSession } from '../utils'

export default function Dashboard() {
  const [iframeUrl, setIframeUrl] = useState('')

  useEffect(() => {
    const url = getIframeUrl()
    setIframeUrl(url)
    return () => {
    }
  }, [])
  

  if (!validateSession()) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <div>
        <iframe src={iframeUrl} title='Dashboard SMG' style={{position: 'fixed', border: 'none', width: '100vw', height: '100vh' }} />
      </div>
    </>
  );
}
