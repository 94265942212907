import React, { useEffect, useState, useContext } from 'react'
import { SideBarContext } from '../App'
import { getAppointment } from '../services/appointments/getAppointment';
import AppointmentContent from '../components/Appointment/AppointmentContent';

const initalState = {
  "created": 0,
  "user": {
      "user_id": "",
      "profile_id": "",
      "name": "",
      "lastname": null,
      "gender": "",
      "age": 40,
      "dni": null,
      "document": "_",
      "smg": null,
      "smg_str": null,
      "phone_number": null,
      "email": "",
      "pregnancy": false,
      "lactancy": false,
      "aco": "",
      "aco_number": "0"
  },
  "modified": 0,
  "order_number": 0,
  "processed": false,
  "subject": "",
  "title": "",
  "summary": "",
  "id": "0",
  "category": ""
}

const Appointment = ({match}) => {

  const [appointment, setAppointment] = useState(initalState)

  const {isCollapsed} = useContext(SideBarContext)

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const {data, status} = await getAppointment(match.params.id)
        if (status === 200){
          setAppointment(data)
        } else {
          alert('Error al traer el pedido')
          window.location.replace("/appointments")
        }
      } catch (error) {
        alert('Error al traer el pedido')
        window.location.replace("/appointments")
      }
    }
    fetchAppointments()
    return () => {
      
    }
  }, [match.params.id])

  return (
    <div style={isCollapsed ? {marginLeft: '84px', transition: 'all 0.3s', marginTop: '2%'} : {marginLeft: '344px', transition: 'all 0.3s', marginTop: '2%'}}>
      { appointment.order_number &&
        <AppointmentContent match={match}/>
      }
    </div>
  )
}

export default Appointment
