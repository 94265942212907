import React, { useState, useContext } from 'react'
import { Redirect } from "react-router-dom"
import { validateSession } from '../utils'
import { SideBarContext } from '../App'
import AppointmentsTable from '../components/Appointments/AppointmentsTable'
import DialogAppointment from '../components/Appointment/DialogAppointment'

const Appointments = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [appointmentId, setAppointmentId] = useState('')
  const {isCollapsed} = useContext(SideBarContext)

  let params = window.location.search.replace('?', '')
  let queryParams = new URLSearchParams(window.location.search)
  if (queryParams.has('p_id')){
    if (localStorage.getItem('dashboard_profile') === 'doctor') {
      window.location.replace(`/appointments/${queryParams.get('p_id')}`)
    }
  }

  const handleClickOpen = (id) => {
    setAppointmentId(id)
    setOpenDialog(true)
  };

  const handleClose = () => {
    setOpenDialog(false)
    window.location.reload()
  };

  if (!validateSession()) {
    return <Redirect to="/login" />
  }
  
  return (
    <div style={isCollapsed ? {marginLeft: '84px', transition: 'all 0.3s'} : {marginLeft: '344px', transition: 'all 0.3s'}}>
      <DialogAppointment appointmentId={appointmentId} open={openDialog} handleClose={handleClose} />
      <AppointmentsTable params={params} handleClickOpen={handleClickOpen} />
    </div>
  )
}

export default Appointments
