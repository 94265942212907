/* eslint-disable no-extend-native */
import React from 'react'
import MaterialTable from '@material-table/core'
import { Tooltip } from '@material-ui/core'
import warningDictionary from '../../dictionaries/warning.json'

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

export default function DrugsTable({drugs}) {
  return (
    <div style={{ maxWidth: "100%", marginTop: '16px', marginBottom: '8px' }}>
      <MaterialTable
        columns={[
          { 
            title: "Nombre Comercial", 
            field: "commercial_name", 
            type: "string",
            width: "30%",
            headerStyle: {textAlign: 'center', fontWeight: 'bold'},
            render: rowData => {
              return (
                <span>{`${rowData.commercial_name?.capitalize()} (${rowData.name?.capitalize()}, ${rowData.presentation?.capitalize()}) - ${rowData.classification ? rowData.classification : ''}`}</span>
              )
            },
          },
          {
            title: "Droga",
            field: "name",
            type: "string",
            width: "25%",
            headerStyle: {textAlign: 'center', fontWeight: 'bold'},
            render: rowData => {
              return `${rowData.name.capitalize()}`
            },
          },
          {
            title: "Dosis y Frecuencia",
            field: "dose_free",
            type: "string",
            width: "15%",
            headerStyle: {textAlign: 'center', fontWeight: 'bold'},
          },
          {
            title: "Alergia / Efecto adverso",
            field: "allergy",
            type: "string",
            width: "10%",
            headerStyle: {textAlign: 'center', fontWeight: 'bold'},
            render: rowData => {
              return `${(rowData.allergy?.capitalize() || '-')}`
            },
          },
          { 
            title: "Advertencia", field: "warning", width: "15%", type: "string", headerStyle: {textAlign: 'center', fontWeight: 'bold'},
            render: rowData => {
              const warning = warningDictionary.find(e => e.warning === rowData.warning)
              if (!warning) {
                return <b>{'-'}</b>
              }
              return (
                <Tooltip
                  placement="top"
                  arrow
                  title={<div style={{ fontSize: "medium", margin: "6px 0" }}>{warning.description}</div>}
                >
                  <b>{warning.title}</b>
                </Tooltip>
              )
            }
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          showTitle: false,
          search: false,
          paging: false,
          sorting: false,
          toolbar: false,
          draggable: false,
          rowStyle: (x, index) => {
            if (index % 2) {
              return { fontFamily: "roboto", fontSize: "small" }
            }
            return { fontFamily: "roboto", fontSize: "small", backgroundColor: '#EEEEEE', borderTopLeftRadius: '8px' }
          },
        }}
        data={drugs}
        localization={{
          toolbar: {
              nRowsSelected: '{0} registro(s) seleccionado(s)',
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar por apellido o número de orden'
          },
          header: {
              actions: 'Acciones'
          },
          body: {
              emptyDataSourceMessage: 'No hay registros',
              filterRow: {
                  filterTooltip: 'Filtro'
              }
          },
          pagination: {
            labelRowsSelect: 'registros',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera página',
            previousTooltip: 'Anterior',
            nextTooltip: 'Siguiente',
            lastTooltip: 'Última página'
          }
        }}
      />
    </div>
  );
}
