import React, { useContext } from 'react'
import { SideBarContext } from '../App'
import PermissionsTable from '../components/Permissions/PermissionsTable';

export default function Permissions() {

  const {isCollapsed} = useContext(SideBarContext)

  return (
    <div style={isCollapsed ? {marginLeft: '84px', transition: 'all 0.3s'} : {marginLeft: '344px', transition: 'all 0.3s'}}>
      <PermissionsTable />
    </div>
  );
}
