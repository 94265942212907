import React, { useState } from 'react'
import { Button, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core'
import { Clear, Cached } from '@material-ui/icons'

export const TableHeader = ({tableRef, params, getAssignedToday}) => {
  const [userSearch, setUserSearch] = useState('')
 

  return (
    <Grid container style={{marginBottom: '22px'}} alignItems="center">
      <Grid item xs={4}>
        <Typography variant='h6' >Listado de pedidos</Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          size='small'
          placeholder='Buscar'
          variant='outlined'
          style={{float: 'right', width:'80%'}}
          onChange={e => {
            setUserSearch(e.target.value)
            tableRef.current.onSearchChangeDebounce(userSearch)
          }}
          value={userSearch}
          InputProps={{
            endAdornment: 
            <InputAdornment position="start">
              <IconButton
                edge="end"
                onClick={() => {
                  setUserSearch('')
                  tableRef.current.onSearchChangeDebounce('')
                }}
              >
                  <Clear />
              </IconButton>
            </InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          select
          style={{float: 'right', width:'80%'}}
          size='small'
          variant='outlined'
          value={params || 'nofilter'}
          onChange={e => {e.target.value !== 'nofilter' ? window.location.replace(`/appointments?${e.target.value}`) : window.location.replace('/appointments') }}
        >
          <MenuItem value='nofilter'> Todos los pedidos </MenuItem>
          <MenuItem value={'processed=true'}>Pedidos procesados</MenuItem>
          <MenuItem value={'processed=false'}>Pedidos sin procesar</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          endIcon={<Cached />}
          style={{float: 'right', width:'80%', backgroundColor: '#F59D00'}}
          onClick={() => {
            tableRef.current && tableRef.current.onQueryChange()
          }}
        >
          ACTUALIZAR
      </Button>
      </Grid>
    </Grid>
  )
}
